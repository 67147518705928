




















import { Component, Prop, Vue } from "vue-property-decorator";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";

@Component({
  components: {
    AppLabel,
    AppWidget,
  },
})
export default class AppOrderInstructionsWidget extends Vue {
  @Prop({ type: String, required: false })
  readonly instructions!: string;

  @Prop({ type: Boolean, default: false })
  readonly isStatic!: boolean;

  @Prop({
    type: String,
    default: "Add a special request for the shipper...",
  })
  readonly placeholder!: string;
}
