


















































































































import { Component, Vue } from "vue-property-decorator";
import AppAddressWidget from "@/components/widgets/AppAddressWidget.vue";
import AppCCWidget from "@/components/widgets/AppCCWidget.vue";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCancellationModal from "@/components/modals/AppCancellationModal.vue";
import AppConfirmReplaceCartModal from "@/components/modals/AppConfirmReplaceCartModal.vue";
import AppOrderInstructionsWidget from "@/components/widgets/AppOrderInstructionsWidget.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppLineItem from "@/components/AppLineItem.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import cart from "@/store/cart";
import { findOrder } from "@/utils/api";
import shipmentStore from "@/store/shipment";
import { createErrorNotification, Notification } from "@/utils/notification";
import * as exception from "@/utils/exception";
import {
  hasRequestorPermissions,
  hasShipperPermissions,
} from "@/utils/authorization";

@Component({
  components: {
    AppCCWidget,
    AppAddressWidget,
    AppAlert,
    AppButton,
    AppCancellationModal,
    AppLineItem,
    AppListGroup,
    AppListGroupItem,
    AppConfirmReplaceCartModal,
    AppOrderInstructionsWidget,
    AppWidget,
    TheHeader,
    TheMain,
  },
})
export default class OrderView extends Vue {
  isRequestor = false;
  isShipper = false;
  notification: Notification | null = null;
  order: pro.OrderDetail | null = null;
  selectedLines: pro.Line[] = [];
  savingCancellation = false;
  showCancellationModal = false;
  showReplaceCartModal = false;

  /**
   * The order's Catalog ID
   */
  get catalogId(): pro.Id {
    return Number(this.$route.params.catalogId);
  }

  /**
   * The order ID
   */
  get orderId(): pro.Id {
    return Number(this.$route.params.orderId);
  }

  /**
   * Gets the order's line items
   */
  get lines(): pro.Line[] {
    if (this.order && this.order.lines) return this.order.lines;
    return [];
  }

  /**
   * Checks whether an order has lines that can either be cancelled or shipped
   */
  get hasSelectableLines(): boolean {
    const lines = this.lines.filter((line: pro.Line) => {
      return line.order && !line.cancellation && !line.shipment;
    });
    return lines.length > 0;
  }

  async mounted() {
    const catalog = { id: this.catalogId };
    this.isRequestor = await hasRequestorPermissions(catalog);
    this.isShipper = await hasShipperPermissions(catalog);
    await this.findOrder();
  }

  /**
   * Handles the response after line items are cancelled
   */
  cancellationHandler(res: {
    cancellation: pro.Cancellation;
    error?: Error;
  }): void {
    this.showCancellationModal = false;
    if (res.error) {
      this.notification = createErrorNotification(
        "Unable to cancel lines",
        res.error.message
      );
    } else if (this.order) {
      this.selectedLines = [];
      this.order.lines = this.lines.map((line: pro.Line) => {
        const cancelledLines = res.cancellation.lines ?? [];
        const cancelledLineIds = cancelledLines.map(cl => cl.id);
        if (cancelledLineIds.includes(line.id)) {
          line.cancellation = { id: res.cancellation.id };
        }
        return line;
      });
    }
  }

  /**
   * Initiates a new shipment
   */
  createShipment(): void {
    shipmentStore.create({
      catalog: this?.order?.catalog,
      toAddress: this?.order?.consignee,
      lines: this.selectedLines,
    });
    this.$router.push({
      name: "SHIPMENT_CREATE",
      params: { catalogId: String(this.catalogId) },
    });
  }

  /**
   * Clones the Order to the Cart
   */
  reorder(overwrite = false): void {
    if (!this.order || !this.order.lines || !this.order.consignee) return;

    try {
      this.order.lines.forEach((line: pro.Line) => {
        cart.addToCart(
          { unit: line.unit, qty: line.qty },
          { id: this.catalogId },
          overwrite
        );
      });
      cart.updateConsignee(this.order.consignee);
      this.$router.push({ name: "ORDER_CART" });
    } catch (err) {
      if (err instanceof exception.ConflictException) {
        this.showReplaceCartModal = true;
      }
    }
  }

  /**
   * Gets the index of the selected line
   */
  selectedLineIndex(line: pro.Line): number {
    return this.selectedLines.findIndex((el: pro.Line) => el.id === line.id);
  }

  /**
   * Calculates the number or lines selected
   */
  selectedLineCount(): number | null {
    const count = this.selectedLines.length;
    if (count < 1) return null;
    return count;
  }

  /**
   * Whether the line item is selected
   */
  isSelected(line: pro.Line): boolean {
    return this.selectedLineIndex(line) > -1;
  }

  /**
   * Whether all line items are selected
   */
  allAreSelected(): boolean {
    return (
      this.selectedLines.length > 0 &&
      this.selectedLines.length === this.lines.length
    );
  }

  /**
   * Whether none of the line items are selected
   */
  noneAreSelected(): boolean {
    return this.selectedLines.length < 1;
  }

  /**
   * Selects/de-selects all line items
   */
  toggleAllLines(select: boolean): void {
    if (select && this.lines) {
      this.selectedLines = this.lines.filter((line: pro.Line) => {
        return !line.cancellation && !line.shipment;
      });
    } else this.selectedLines = [];
  }

  /**
   * Selects/de-selects a line item
   */
  toggleLine(line: pro.Line): void {
    const index = this.selectedLineIndex(line);
    if (index > -1) this.selectedLines.splice(index, 1);
    else this.selectedLines.push(line);
  }

  /**
   * Finds an order
   */
  async findOrder(): Promise<void> {
    this.order = await findOrder({
      id: this.orderId,
      catalog: { id: this.catalogId },
    });
  }
}
