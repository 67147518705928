
























import AppWidget from "@/components/core/AppWidget.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    AppWidget,
  },
})
export default class AppCCWidget extends Vue {
  @Prop({ type: String, required: false })
  readonly cc!: string;

  @Prop({ type: Boolean, default: false })
  readonly isStatic!: boolean;
}
