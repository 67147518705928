














import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppConfirmModal from "@/components/modals/AppConfirmModal.vue";
import { createCancellation } from "@/utils/api";

@Component({ components: { AppButton, AppConfirmModal } })
export default class AppCancellationModal extends Vue {
  @Prop({ required: true })
  readonly linesToBeCancelled!: pro.Line[];

  @Prop({ required: true })
  readonly catalog!: pro.Catalog;

  saving = false;

  async cancelLines(): Promise<void> {
    let error: Error | undefined = undefined;
    let cancellation: pro.Cancellation | undefined = undefined;
    try {
      this.saving = true;
      cancellation = await createCancellation(
        this.linesToBeCancelled,
        this.catalog
      );
      cancellation.lines = this.linesToBeCancelled;
    } catch (err) {
      error = err;
    } finally {
      this.saving = false;
      this.cancelled(cancellation, error);
    }
  }

  cancelled(cancellation?: pro.Cancellation, error?: Error) {
    this.$emit("cancelled", { cancellation, error });
  }
}
