



































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppModal from "@/components/core/AppModal.vue";

@Component({ components: { AppButton, AppModal } })
export default class AppConfirmModal extends Vue {
  @Prop({ type: String, default: "Yes, do it" })
  readonly confirmButtonText!: string;

  decline() {
    this.$emit("decline");
  }

  confirm() {
    this.$emit("confirm");
  }
}
