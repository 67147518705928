













import { Component, Vue } from "vue-property-decorator";
import AppConfirmModal from "@/components/modals/AppConfirmModal.vue";

@Component({ components: { AppConfirmModal } })
export default class AppConfirmReplaceCartModal extends Vue {}
