import store from "./";

class ShipmentStore {
  static readonly SHIPMENT = "pro_shipment";

  get catalogId(): pro.Id | null {
    if (!this.shipment || !this.shipment.catalog) return null;
    return this.shipment.catalog?.id ?? null;
  }

  get lineCount(): number {
    if (
      !this.shipment ||
      !this.shipment.lines ||
      !Array.isArray(this.shipment.lines)
    )
      return 0;
    return this.shipment.lines.length;
  }

  get lines(): pro.Line[] {
    return this.shipment.lines ?? [];
  }

  get shipment(): pro.Shipment {
    return (store.get(ShipmentStore.SHIPMENT) as pro.Shipment) ?? null;
  }

  set shipment(shipment: pro.Shipment) {
    store.set(ShipmentStore.SHIPMENT, shipment);
  }

  create(shipment: pro.Shipment) {
    this.shipment = {
      ...shipment,
      account: "",
      carrier: "UPS",
      parcels: [],
      service: "",
    };
  }

  destroy() {
    store.get(ShipmentStore.SHIPMENT);
  }

  setFromAddress(address: pro.Address): void {
    this.shipment = { ...this.shipment, fromAddress: address };
  }
}

const shipmentStore = new ShipmentStore();
export default shipmentStore;
